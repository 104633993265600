import { memo } from 'react'

declare global {
  interface Window {
    gtag: any
    _paq: any
  }
}

export const GoogleAnalytics = memo(function GoogleAnalytics() {
  return null
})
