import styled from '@emotion/styled'
import { Ribbon } from 'app/components/Common/Ribbon'
import { HeaderContext } from 'app/containers/Header'
import { motion } from 'framer-motion'
import { compact } from 'lodash'
import React, { memo, useContext } from 'react'

export interface Props {
  modalStatus?: boolean
}

export const Benefits = memo(function Benefits({ modalStatus }: Props) {
  const { benefits, languageCode } = useContext(HeaderContext) as any

  const variants = {
    visible: (i: any) => ({
      opacity: 1,
      x: 0,
      transition: {
        damping: 25,
        delay: i * 0.25 + 0.8,
        duration: 0.4,
        type: 'spring',
      },
    }),
    hidden: { opacity: 0, x: '-3.75rem' },
  }

  const translation = compact(
    benefits?.translations.filter(
      (t: any) => t.languages_code.code === languageCode,
    ),
  )[0] as any

  const checklist = translation?.benefits_list.map(({ facilities_id }: any) => {
    const translation = facilities_id.translations?.find(
      (t: any) => t?.languages_code?.code === languageCode,
    )

    const icon = facilities_id.icon.file.publicURL || undefined
    const label = translation?.label
    return label
      ? {
          icon,
          label,
        }
      : undefined
  })
  const description = translation?.description || undefined
  const title = translation?.title || undefined

  return (
    <Container className={modalStatus ? 'open' : undefined}>
      <Ribbon isAnimated modalStatus={modalStatus} />
      {title ? (
        <Title
          initial={{ opacity: 0, x: '-3.75rem' }}
          animate={modalStatus ? { opacity: 1, x: 0 } : undefined}
          transition={{
            damping: 25,
            delay: 0.2,
            duration: 0.4,
            type: 'spring',
          }}
        >
          {title}
        </Title>
      ) : undefined}
      {description ? (
        <Description
          dangerouslySetInnerHTML={{ __html: description }}
          initial={{ opacity: 0, x: '-3.75rem' }}
          animate={modalStatus ? { opacity: 1, x: 0 } : undefined}
          transition={{
            damping: 25,
            delay: 0.4,
            duration: 0.4,
            type: 'spring',
          }}
        />
      ) : null}
      {checklist ? (
        <Checklist>
          {checklist.map((item: any, index: any) => (
            <Check
              key={index}
              custom={index}
              animate={modalStatus ? 'visible' : 'hidden'}
              variants={variants}
            >
              {item.icon ? (
                <SVG alt={item.label} src={item.icon} width="30" height="30" />
              ) : null}
              <Label>{item.label}</Label>
            </Check>
          ))}
        </Checklist>
      ) : null}
    </Container>
  )
})

const Container = styled.div``

const Title = styled(motion.div)`
  color: ${({ theme }) => theme.colors.variants.primaryLight1};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 4.375rem;
  font-weight: 700;
  line-height: 4rem;
  text-transform: uppercase;
`

const Description = styled(motion.div)`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  line-height: 1.75rem;
  margin-top: 1.25rem;
`

const Checklist = styled.div`
  margin-top: 3.75rem;
`

const Check = styled(motion.div)`
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-top: 1.625rem;
  &:first-of-type {
    margin-top: 0;
  }
`

const SVG = styled.img`
  width: 1.875rem;
  height: auto;
  margin-right: 1.3125rem;
`

const Label = styled.div`
  width: calc(100% - 3.1875rem);
  color: ${({ theme }) => theme.colors.variants.primaryLight1};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.25rem;
  text-transform: uppercase;
`
