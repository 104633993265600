import styled from '@emotion/styled'
import { Language } from 'app/components/Icons'
import { Link } from 'gatsby'
import React, { memo } from 'react'

export interface Props {
  className?: string
  pageID?: string
  label: string
  languagePrefix: string | null
  URL: string
}

export const LanguageItem = memo(function LanguageItem({
  className,
  label,
  languagePrefix,
  URL,
}: Props) {
  return (
    <Anchor
      className={className}
      to={URL}
      lang={languagePrefix === label ? undefined : label}
    >
      <Label>{label}</Label>
      <Language />
    </Anchor>
  )
})

const Anchor = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.75rem;
  height: 3.75rem;
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.25rem;
  text-transform: uppercase;
  transition: 0.3s ease-in-out;
  &:hover {
    color: ${({ theme }) => theme.colors.variants.primaryLight1};
  }
  &.active {
    background: none;
    color: ${({ theme }) => theme.colors.variants.neutralLight3};
    pointer-events: none;

    span {
      display: none;
    }
    svg {
      display: block;
    }
  }

  svg {
    display: none;
    width: auto;
    height: 1.25rem;
    fill: ${({ theme }) => theme.colors.variants.neutralLight3};
  }

  @media (max-width: 1199px) {
    svg {
      fill: ${({ theme }) => theme.colors.variants.neutralLight2};
    }
  }
`

const Label = styled.span``
