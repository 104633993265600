import styled from '@emotion/styled'
import { theme } from 'app/theme'
import React, { memo, useEffect, useState } from 'react'

export interface Props {
  IBEID?: number
  languagePrefix: string
  modalStatus?: boolean
}

export const Syncrobox = memo(function Syncrobox({
  IBEID = 1,
  languagePrefix,
  modalStatus,
}: Props) {
  const [firstLoad, setFirstLoad] = useState(true)

  useEffect(() => {
    const script = document.createElement('script')
    script.async = true

    if (modalStatus && firstLoad) {
      setFirstLoad(false)

      script.innerHTML = `
    (function (i, s, o, g, r, a, m) {
        i['SBSyncroBoxParam'] = r; i[r] = i[r] || function () {
            (i[r].q = i[r].q || []).push(arguments)
        }, i[r].l = 1 * new Date(); a = s.createElement(o),
        m = s.getElementsByTagName(o)[0]; a.async = 1; a.src = g; m.parentNode.insertBefore(a, m)
    })(window, document, 'script', 'https://cdn.simplebooking.it/search-box-script.axd?IDA=${IBEID}','SBSyncroBox');
    SBSyncroBox({
        CodLang: '${languagePrefix.toLocaleUpperCase()}',
        Addresses: {
          HttpHost: 'https://hotelgiolli.simplebooking.it'
        },
        
        
        Styles: {
          Theme: 'light-pink',
          FontFamily: "'${theme.fontFamily.heading}'",
          CustomColor: '${theme.colors.variants.neutralDark2}',
          CustomBGColor: 'transparent',
          CustomButtonBGColor: '${theme.colors.variants.primaryLight1}',
          CustomButtonHoverBGColor: '${theme.colors.variants.neutralLight2}',
          CustomLinkColor: '${theme.colors.variants.neutralDark2}',
          CustomIconColor: '${theme.colors.variants.primaryLight1}',
          CustomBoxShadowColorFocus: '${theme.colors.variants.neutralDark2}',
          CustomBoxShadowColorHover: '${theme.colors.variants.neutralDark2}',
          CustomAccentColor: '${theme.colors.variants.primaryLight1}',
          CustomAccentColorHover: '${theme.colors.variants.primaryLight1}',
          CustomColorHover: '${theme.colors.variants.primaryLight1}',
          CustomLabelColor: '${theme.colors.variants.primaryLight1}',
          CustomLabelHoverColor: '${theme.colors.variants.primaryLight1}',
          CustomFieldBackgroundColor: 'transparent',
        },

        Labels: {
          CheckinDate: {'DE': 'Ankunft', 'EN': 'Arrival', 'ES': 'Llegada', 'FR': 'Arrivée', 'IT': 'Arrivo', 'RU': 'Пребытие'},
          CheckoutDate: {'DE': 'Abfahrt', 'EN': 'Departure', 'ES': 'Salida', 'FR': 'Départ', 'IT': 'Partenza', 'RU': 'Выезд'},
          NumPersons: {'DE': 'Gäste', 'EN': 'Guests', 'ES': 'Invitados', 'FR': 'Invités', 'IT': 'Ospiti', 'RU': 'Гостей'},
          NumAdults: {'DE': 'Personen', 'EN': 'Adults', 'ES': 'Adultos', 'FR': 'Adultes', 'IT': 'Adulti', 'RU': 'Взрослые'},
          NumKids: {'DE': 'Kinder', 'EN': 'Kids', 'ES': 'Niños', 'FR': 'Enfants', 'IT': 'Bambini', 'RU': 'Дети'},
          PromoCode: {'DE': 'Gutscheincode', 'EN': 'Promo Code', 'ES': 'Código promocional', 'FR': 'Code Promo', 'IT': 'Codice Promo', 'RU': 'Промо код'},
          ModCancReservation: {'DE': 'Reservierung ändern/Stornieren', 'EN': 'Modify/Cancel Reservation', 'ES': 'Modificar/Cancelar reserva', 'FR': 'Modifier/Annuler la réservation', 'IT': 'Modifica/Cancella la Prenotazione', 'RU': 'Изменить/отменить бронирование'},
        }
        
    });
    `

      document.body.appendChild(script)
    }

    return () => {
      if (modalStatus && firstLoad) {
        document.body.removeChild(script)
      }
    }
  }, [modalStatus, firstLoad])

  return (
    <Container>
      <Widget id="sb-container" />
    </Container>
  )
})
const Container = styled.div`
  max-width: 480px;

  #sb-container {
    .sb {
      padding: 0 !important;
    }
    .sb-custom-widget-element-hover-color:hover {
      color: ${({ theme }) => theme.colors.variants.neutralLight4} !important;
    }
    .sb__footer-promo-input.sb-custom-bg-color {
      background: ${({ theme }) =>
        theme.colors.variants.neutralLight3} !important;
    }
    .sb__guests-children-age-select.sb-custom-bg-color {
      background: ${({ theme }) =>
        theme.colors.variants.neutralLight3} !important;
    }
    .sb-custom-box-shadow-color,
    .sb-custom-box-shadow-color-hover,
    .focus .sb-custom-box-shadow-color-focus {
      box-shadow: none !important;
      padding: 0;
      &:before {
        display: none !important;
      }
      &:hover {
        box-shadow: none !important;
      }
    }
    .sb-custom-box-shadow-color:focus {
      box-shadow: none !important;
    }
    .sb-custom-widget-element-hover-bg-color:hover {
      background: ${({ theme }) =>
        theme.colors.variants.neutralLight1} !important;
    }
    .sb-custom-box-shadow-color {
      border-color: ${({ theme }) =>
        theme.colors.variants.neutralLight3} !important;
    }
    #sb-container_sb__form-field--checkavailability {
      height: 3.75rem;
      font-family: ${({ theme }) => theme.fontFamily.heading};
      font-size: 1.25rem;
      letter-spacing: 0.0625rem;
      line-height: 1.5rem;
      margin-top: 2.5rem;
      text-transform: uppercase;
    }
  }

  .sb__form-field-label {
    display: inline-block;
  }

  .sb__form-field-input {
    .sb__form-field-icon {
      margin-top: 2rem;
    }
  }

  .sb__form-field-date-wrapper,
  .sb__form-field-property {
    margin-top: 1.4375rem;
  }

  .sb__dates {
    display: flex;
    align-items: flex-end;
    .sb__form-field {
      width: 50%;
    }
    .sb__form-field--checkin,
    .sb__form-field--checkout {
      width: calc(50% - 1.5rem);
      border-bottom: 0.625rem solid
        ${({ theme }) => theme.colors.variants.neutralLight3};
      padding-bottom: 0.875rem;
      .sb__form-field-label {
        font-family: ${({ theme }) => theme.fontFamily.heading};
        font-size: 1.125rem;
        font-weight: 600;
        line-height: 20px;
        margin-bottom: 0.75rem;
        text-transform: uppercase;
      }
      .sb__form-field-date-number {
        font-family: ${({ theme }) => theme.fontFamily.heading};
        font-size: 0.875rem;
        font-size: 4.375rem;
        font-weight: 700;
        line-height: 4rem;
      }
      .sb__form-field-month-year {
        max-width: 100px;
        color: ${({ theme }) => theme.colors.variants.neutralDark2};
        font-family: ${({ theme }) => theme.fontFamily.heading};
        font-size: 1rem;
        font-weight: 500;
        line-height: 1.25rem;
        margin-left: 0.5rem;
        text-transform: uppercase;
      }
      .sb__form-field-weekday {
        color: ${({ theme }) => theme.colors.variants.neutralDark2};
        font-family: ${({ theme }) => theme.fontFamily.paragraph};
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.25rem;
        margin-left: 0.5rem;
      }
      .sb__form-field-input {
        height: 4rem;
        border-radius: 0 !important;
        box-shadow: none !important;
      }
    }
    .sb__form-field--checkout {
      margin-left: 3rem;
    }
    .sb__calendar {
      border-radius: 0 !important;
      box-shadow: none !important;
      border: 0.625rem solid
        ${({ theme }) => theme.colors.variants.neutralLight3};
      margin-top: -10px;
    }
    .sb__calendar-btn {
      box-shadow: none !important;
      color: ${({ theme }) => theme.colors.variants.neutralLight1} !important;
      .icon {
        fill: ${({ theme }) => theme.colors.variants.neutralDark2} !important;
      }
    }
    .sb__calendar-days {
      .sb__calendar-day {
        border-radius: 0 !important;
      }
    }
  }

  .sb__guests {
    border: 0.625rem solid ${({ theme }) => theme.colors.variants.neutralLight3};
    border-radius: 0 !important;
    padding: 1rem !important;
    .sb__panel-actions {
      button {
        font-family: ${({ theme }) => theme.fontFamily.heading};
        font-size: 1.125rem;
        font-weight: 400;
        line-height: 1.25rem;
        margin-top: 0 !important;
      }
      .sb__btn--secondary {
        background: ${({ theme }) => theme.colors.variants.neutralLight3};
        &:hover {
          color: ${({ theme }) =>
            theme.colors.variants.neutralLight4} !important;
        }
      }
    }
  }

  .sb__guests-rooms {
    margin-top: 2.5rem;
    border-bottom: 0.625rem solid
      ${({ theme }) => theme.colors.variants.neutralLight3};
    padding-bottom: 0.875rem;
    .sb__form-field {
      .sb__form-field-label {
        font-family: ${({ theme }) => theme.fontFamily.heading};
        font-size: 1.125rem;
        font-weight: 600;
        line-height: 20px;
        margin-bottom: 0.75rem;
        text-transform: uppercase;
      }
      .sb__form-field-guests {
        font-weight: 700;
      }
      .sb__form-field-date-number {
        font-family: ${({ theme }) => theme.fontFamily.heading};
        font-size: 0.875rem;
        font-size: 4.375rem;
        font-weight: 700;
        line-height: 4rem;
      }
      .sb__form-field-guests {
        font-family: ${({ theme }) => theme.fontFamily.heading};
        font-size: 1rem;
        font-weight: 600;
        line-height: 1.25rem;
        margin-left: 0.5rem;
      }
      .sb__form-field-rooms {
        color: ${({ theme }) => theme.colors.variants.neutralDark2};
        font-family: ${({ theme }) => theme.fontFamily.paragraph};
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.25rem;
        margin-left: 0.5rem;
      }
      .sb__form-field-icon {
        .icon {
          width: 1.875rem;
          height: 1.875rem;
        }
      }
      #sb-container_sb__form-field--guests {
        height: 4rem;
        box-shadow: none !important;
        border-radius: 0 !important;
      }
    }
  }

  .sb__guests-room {
    padding-bottom: 1rem;
    .sb__guests-room-header {
      margin-bottom: 1rem;
    }
    .sb__guests-room-label {
      font-family: ${({ theme }) => theme.fontFamily.heading};
      font-size: 1.125rem;
      font-weight: 600;
      color: ${({ theme }) => theme.colors.variants.neutralDark2};
      line-height: 1.25rem;
      text-transform: uppercase;
    }
    .sb__guests-room-header-divider {
      display: none;
    }
    .sb__guests-children-age-select {
      width: 60px;
      height: 1.75rem;
      font-family: ${({ theme }) => theme.fontFamily.heading};
      font-size: 1.125rem;
      font-weight: 600;
      border-radius: 0 !important;
      padding: 0 0.75rem !important;
      text-align: center;
      transform: translateY(-2px);
    }
  }

  .sb__guests-add-room,
  .sb__guests-children-age-label {
    font-family: ${({ theme }) => theme.fontFamily.heading};
    font-size: 1.125rem;
    line-height: 1.25rem;
    text-transform: uppercase;
  }

  .sb__guests-room-remove {
    width: 1.75rem;
    height: 1.75rem;
    border-left: 1px solid ${({ theme }) => theme.colors.variants.neutralLight1};
    margin-left: 0.5rem;
    padding-left: 0.5rem;
    svg {
      transform: scale(2) translateY(1px);
      * {
        fill: ${({ theme }) => theme.colors.variants.primaryLight1} !important;
      }
    }
  }

  .sb__guests-adults-label,
  .sb__guests-children-label {
    font-family: ${({ theme }) => theme.fontFamily.paragraph};
    font-size: 0.875rem;
    line-height: 1.125rem;
  }

  .sb__btn {
    border-radius: 0;
    &.sb__btn--block.sb__btn--verify {
      height: 3.75rem;
      text-align: center;
      font-size: 1.125rem;
      margin-top: 3.5rem;
    }
  }

  .sb__footer {
    .sb__footer-actions {
      display: flex;
      margin-top: 1.25rem;
      a {
        width: 70%;
        font-family: ${({ theme }) => theme.fontFamily.heading};
        font-size: 1.125rem;
        font-weight: 600;
        line-height: 1.25rem;
        text-align: right;
        text-transform: uppercase;
        .icon {
          display: none !important;
        }
      }
      .sb__footer-promo-wrapper {
        display: inline-block;
        width: 30%;
        white-space: nowrap;
        a {
          width: 100%;
          text-align: left;
        }
      }
      .sb__footer-promo {
        display: flex;
        border: 0.625rem solid
          ${({ theme }) => theme.colors.variants.neutralLight3};
        border-radius: 0 !important;
        padding: 1rem !important;
        input {
          height: 1.875rem;
          font-family: ${({ theme }) => theme.fontFamily.paragraph};
          border-radius: 0 !important;
          padding: 0 0.75rem !important;
        }
      }
      .sb__footer-link--promo-value {
        display: none;
      }
      .sb__footer-promo-label {
        font-family: ${({ theme }) => theme.fontFamily.heading};
        font-size: 1.125rem;
        font-weight: 600;
        color: ${({ theme }) => theme.colors.variants.neutralDark2};
        line-height: 1.25rem;
        text-transform: uppercase;
      }
    }
    .sb__panel-actions {
      margin-top: 1rem;
      button {
        font-family: ${({ theme }) => theme.fontFamily.heading};
        font-size: 1.125rem;
        font-weight: 400;
        line-height: 1.25rem;
        margin-top: 0 !important;
      }
      .sb__btn--secondary {
        background: ${({ theme }) => theme.colors.variants.neutralLight3};
        &:hover {
          color: ${({ theme }) =>
            theme.colors.variants.neutralLight4} !important;
        }
      }
    }
  }
`

const Widget = styled.div``
