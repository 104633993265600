import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Gift } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { HeaderContext } from 'app/containers/Header'
import { theme } from 'app/theme'
import { useVocabularyData } from 'app/utils/vocabulary'
import { rgba } from 'emotion-rgba'
import { Link } from 'gatsby'
import { compact } from 'lodash'
import React, { memo, useContext, useEffect, useState } from 'react'

interface Props {}

export const Toolbars = memo(function Toolbars({}: Props) {
  const { IBEID, geniusButton, languageCode, languagePrefix, shopURL } =
    useContext(HeaderContext) as any

  const geniusTranslation = compact(geniusButton?.translations).filter(
    (t: any) => t?.languages_code?.code === languageCode,
  )[0] as any

  const [scrollDir, setScrollDir] = useState('up')

  useEffect(() => {
    const threshold = 0
    let lastScrollY = window.pageYOffset
    let ticking = false

    const updateScrollDir = () => {
      const scrollY = window.pageYOffset

      if (Math.abs(scrollY - lastScrollY) < threshold) {
        ticking = false
        return
      }
      setScrollDir(scrollY > lastScrollY ? 'down' : 'up')
      lastScrollY = scrollY > 0 ? scrollY : 0
      ticking = false
    }

    const onScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(updateScrollDir)
        ticking = true
      }
    }

    window.addEventListener('scroll', onScroll)

    return () => window.removeEventListener('scroll', onScroll)
  }, [scrollDir])

  return (
    <Container
      className={scrollDir == 'down' ? 'hidden' : ''}
      dial={5}
      row
      space="between"
      stretch
    >
      {shopURL ? (
        <Shop aria-label="Shop" href={shopURL} rel="noreferrer" target="_blank">
          <Gift />
        </Shop>
      ) : null}
      {geniusTranslation ? (
        <GeniusButton
          to={
            languagePrefix
              ? `/${languagePrefix}/${geniusTranslation.url}`
              : `/${geniusTranslation.url}`
          }
        >
          Giolli Genius
        </GeniusButton>
      ) : null}
      {IBEID ? (
        <IBEButton
          href={`https://hotelgiolli.simplebooking.it/ibe/hotelbooking/search?hid=${IBEID}&lang=${
            languagePrefix ? languagePrefix.toLocaleUpperCase() : 'EN'
          }`}
          rel="noopener"
          target="_blank"
        >
          {useVocabularyData('book-now', languageCode)}
        </IBEButton>
      ) : null}
    </Container>
  )
})

const Container = styled(FlexBox)`
  width: 100%;
  box-shadow: ${rgba(theme.colors.variants.neutralDark1, 0.16)} 0 3px 10px;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 50;
  transition: 0.3s ease-out;
  &.hidden {
    transform: translateY(6rem);
  }
`

const Style = css`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3.75rem;
  flex: 2;
  font-family: ${theme.fontFamily.heading};
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.5rem;
  text-transform: uppercase;
`

const Shop = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.75rem;
  height: 3.75rem;
  background: ${({ theme }) => theme.colors.variants.neutralLight2};

  svg {
    width: auto;
    height: 1.25rem;
    fill: ${({ theme }) => theme.colors.variants.neutralLight3};
  }
`

const GeniusButton = styled(Link)`
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  color: ${({ theme }) => theme.colors.variants.neutralLight2};

  ${Style}
`

const IBEButton = styled.a`
  background: ${({ theme }) => theme.colors.variants.primaryLight1};
  color: ${({ theme }) => theme.colors.variants.neutralLight4};

  ${Style}
`
