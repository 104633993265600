import styled from '@emotion/styled'
import { Plus } from 'app/components/Icons'
import { HeaderContext } from 'app/containers/Header'
import { Link } from 'gatsby'
import { compact } from 'lodash'
import React, { memo, useContext } from 'react'

export const GeniusButton = memo(function GeniusButton() {
  const { geniusButton, languageCode, languagePrefix } = useContext(
    HeaderContext,
  ) as any

  const translation = compact(geniusButton?.translations).filter(
    (t: any) => t?.languages_code?.code === languageCode,
  )[0] as any

  if (!translation) {
    return null
  }

  const URL = translation.url

  if (geniusButton.status !== 'published' && !URL) {
    return null
  }

  return (
    <Container to={languagePrefix ? `/${languagePrefix}/${URL}` : `/${URL}`}>
      <Plus />
      Giolli Genius
    </Container>
  )
})

const Container = styled(Link)`
  display: flex;
  align-items: center;
  height: 3.75rem;
  background: ${({ theme }) => theme.colors.variants.neutralLight2};
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.25rem;
  font-weight: 600;
  letter-spacing: 0.0625rem;
  line-height: 1.5rem;
  padding: 0 1.25rem;
  position: absolute;
  top: 100vh;
  right: 0;
  text-transform: uppercase;
  transform: rotate(-90deg);
  transform-origin: 64px -64px;
  transition: 0.3s ease-in-out;
  z-index: 3;
  &:hover {
    background: ${({ theme }) => theme.colors.variants.neutralLight1};
  }

  svg {
    width: 1.25rem;
    height: 1.25rem;
    fill: ${({ theme }) => theme.colors.variants.neutralLight4};
    margin-right: 1rem;
  }
`
