import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { HeaderContext } from 'app/containers/Header'
import React, { memo, useContext } from 'react'

import { Benefits } from './Benefits'
import { Syncrobox } from './Syncrobox'

export interface Props {
  modalStatus?: boolean
}

export const ModalIBE = memo(function ModalIBE({ modalStatus }: Props) {
  const { IBEID, languagePrefix } = useContext(HeaderContext) as any

  return (
    <Container row stretch>
      <LeftSide dial={4}>
        <Benefits modalStatus={modalStatus} />
      </LeftSide>
      <RightSide dial={5}>
        <Wrap>
          {IBEID ? (
            <Syncrobox
              IBEID={IBEID}
              languagePrefix={languagePrefix || 'EN'}
              modalStatus={modalStatus}
            />
          ) : null}
        </Wrap>
      </RightSide>
    </Container>
  )
})

const Container = styled(FlexBox)`
  height: 100%;
`

const LeftSide = styled(FlexBox)`
  width: 50%;
  height: 100%;
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  padding: 5.625rem 10.903vw;
`

const RightSide = styled(FlexBox)`
  width: 50%;
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
`

const Wrap = styled.div`
  margin: 0 auto;
  padding: 5.625rem 6.944vw;
`
