import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Minus, Plus } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { theme } from 'app/theme/index'
import { Link } from 'gatsby'
import React, { memo, useEffect, useState } from 'react'

export interface Props {
  childs?: ChildsProps[]
  className?: string
  id?: string
  image?: string
  title: string
  languageCode?: string
  languagePrefix?: string | null
  modalStatus?: boolean
  url: string
}

interface ChildsProps {
  id?: string
  title: string
  url: string
  weight?: number
}

export const MenuItem = memo(function MenuItem({
  childs,
  className,
  image,
  modalStatus,
  title,
  url,
}: Props) {
  if (!title) {
    return null
  }

  const [submenuVisibility, setSubmenuVisibility] = useState(false)

  useEffect(() => {
    setSubmenuVisibility(false)
  }, [])

  return (
    <>
      {url ? (
        <Anchor className={className} to={url} activeClassName="active">
          {image ? (
            <Background className={`item-bg${modalStatus ? ' visible' : ''}`}>
              <Image style={{ backgroundImage: `url(${image})` }} />
            </Background>
          ) : null}

          <Label>{title}</Label>
        </Anchor>
      ) : (
        <NoLink
          dial={4}
          className={submenuVisibility ? 'open' : undefined}
          inline
          onClick={() =>
            submenuVisibility
              ? setSubmenuVisibility(false)
              : setSubmenuVisibility(true)
          }
          row
          tag="span"
        >
          <Label>{title}</Label>
          {submenuVisibility ? <Minus /> : <Plus />}
        </NoLink>
      )}
      {childs && childs.length > 0 ? (
        <Submenu className={`submenu${submenuVisibility ? ' open' : ''}`}>
          {childs
            .sort((a: any, b: any) => a.weight - b.weight)
            .map((item, index) => (
              <ChildItem key={index} {...item} />
            ))}
        </Submenu>
      ) : null}
    </>
  )
})

export const ChildItem = memo(function ChildItem({ title, url }: ChildsProps) {
  return (
    <SubmenuLi>
      <Anchor to={url} activeClassName="active">
        {title}
      </Anchor>
    </SubmenuLi>
  )
})

const Style = css`
  color: ${theme.colors.variants.primaryLight1};
  font-family: ${theme.fontFamily.heading};
  font-size: 6.1vh;
  font-weight: 600;
  line-height: 7.1vh;
  text-transform: uppercase;
  transition: 0.2s ease-out;
  &.active {
    .item-bg {
      z-index: -1;
      &.visible {
        opacity: 1;
        transition: 1s 1.6s ease-out;
      }
      > div {
        filter: none;
        transform: scale(1);
      }
    }
    > span {
      &:before {
        right: 0;
      }
    }
  }
  &:hover {
    .item-bg {
      opacity: 1;
      > div {
        filter: none;
        transform: scale(1);
      }
    }
    > span {
      &:before {
        right: 0;
      }
    }
  }

  @media (max-width: 1199px) {
    font-size: 3.125rem;
    line-height: 2.875rem;
  }

  @media (max-width: 767px) {
    font-size: 2.25rem;
    line-height: 1.875rem;
  }
`

const Anchor = styled(Link)`
  &:hover,
  &.active {
    color: ${theme.colors.variants.primaryLight1};
  }

  ${Style}
`

const NoLink = styled(FlexBox)`
  cursor: pointer;

  svg {
    width: 1.25rem;
    height: auto;
    fill: ${({ theme }) => theme.colors.variants.primaryLight1};
    margin-left: 1rem;

    @media (max-width: 767px) {
      width: 1rem;
    }
  }

  ${Style}
`

const Background = styled.div`
  width: 28vw;
  height: 56vh;
  overflow: hidden;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  top: calc(50% - 3.4vh);
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.3s ease-in-out;
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    background: ${({ theme }) => theme.colors.variants.neutralLight3};
    opacity: 0.8;
    position: absolute;
    top: 0;
    left: 0;
    transform: scale(1.1);
    z-index: 1;
  }

  @media (max-width: 1199px) {
    display: none;
  }
`

const Image = styled.div`
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  filter: blur(8px);
  position: absolute;
  top: 0;
  left: 0;
  transform: scale(1.2);
  transition: 0.3s 0.2s ease-in-out;
`

const Label = styled.span`
  position: relative;
  z-index: 2;
  &:before {
    content: '';
    height: 0.9vh;
    background: ${theme.colors.variants.primaryLight1};
    position: absolute;
    top: calc(50% + 0.2vh);
    right: 100%;
    left: 0;
    transform: translateY(-50%);
    transition: 0.3s ease-in-out;
    z-index: -1;
  }

  @media (max-width: 767px) {
    &:before {
      height: 0.3125rem;
      top: calc(50% + 0.0625rem);
    }
  }
`

const Submenu = styled.ul`
  max-height: 0;
  overflow: hidden;
  transition: 0.5s cubic-bezier(0, 1, 0, 1);
  &.open {
    max-height: 250px;
    transition: 0.6s ease-in-out;
  }
`

const SubmenuLi = styled.li`
  margin-top: 0.75rem !important;
  a {
    color: ${({ theme }) => theme.colors.variants.neutralDark2};
    font-size: 2vh;
    line-height: 2.2vh;
    position: relative;
  }

  @media (max-width: 1023px) {
    a {
      font-size: 1.25rem;
      line-height: 1.25rem;
    }
  }
`
