import styled from '@emotion/styled'
import { Plus } from 'app/components/Icons'
import { motion } from 'framer-motion'
import React, { memo } from 'react'

export interface Props {
  isAnimated?: boolean
  modalStatus?: boolean
}

export const Ribbon = memo(function Ribbon({ isAnimated, modalStatus }: Props) {
  return (
    <Container
      initial={isAnimated ? { opacity: 0, x: '-3.75rem' } : undefined}
      animate={modalStatus ? { opacity: 1, x: 0 } : undefined}
      transition={{
        damping: 25,
        duration: 0.4,
        type: 'spring',
      }}
    >
      <FirstPart>Giolli</FirstPart>
      <SecondPart>Genius</SecondPart>
      <Plus />
    </Container>
  )
})

const Container = styled(motion.div)`
  display: inline-flex;
  flex-direction: row;
  background: ${({ theme }) => theme.colors.variants.primaryLight1};
  border-radius: 1.875rem;
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.25rem;
  line-height: 0.9375rem;
  margin-bottom: 0.75rem;
  padding: 0.75rem 1.3125rem 0.8125rem;

  svg {
    width: auto;
    height: 0.5625rem;
    fill: ${({ theme }) => theme.colors.variants.neutralLight3};
    margin-left: 0.3125rem;
    transform: translateY(0.3125rem);
  }
`

const FirstPart = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralLight3};
  font-weight: 700;
  text-transform: uppercase;
`

const SecondPart = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralLight2};
  margin-left: 0.1875rem;
`
