import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { theme } from 'app/theme'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo } from 'react'

export interface Props {
  languageCode: string
  onClick: () => void
  variant?: Variant
}

export const ModalClose = memo(function ModalClose({
  languageCode,
  onClick,
  variant = 'L',
}: Props) {
  return (
    <Container dial={5} row onClick={onClick} variant={variant}>
      <Icon className="close-icon" />
      {variant !== 'SR' ? (
        <Label>{useVocabularyData('close', languageCode)}</Label>
      ) : null}
    </Container>
  )
})

const Container = styled(FlexBox)<ContainerProps>`
  height: 3.75rem;
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
  cursor: pointer;
  padding: 0 2.6875rem;
  position: fixed;
  top: 0;
  left: 0;
  transition: 0.6s ease-in-out;
  z-index: 2;
  &:hover {
    .close-icon {
      &:before,
      &:after {
        transform: none;
      }
    }
  }

  ${({ variant }) => {
    switch (variant) {
      case 'R':
        return css`
          background: ${theme.colors.variants.neutralLight3};
          right: 0;
          left: auto;
        `
      case 'SR':
        return css`
          width: 3.75rem;
          background: ${theme.colors.variants.neutralLight4};
          padding: 0;
          right: 0;
          left: auto;
        `
    }
  }}

  @media (max-width: 1199px) {
    width: 3.75rem;
    padding: 0;
    right: 0;
    left: auto;
  }
`

const Icon = styled.div`
  display: block;
  width: 20px;
  height: 20px;
  position: relative;
  &:before,
  &:after {
    content: '';
    width: 26px;
    height: 3px;
    background: ${({ theme }) => theme.colors.variants.neutralDark2};
    position: absolute;
    top: 9px;
    left: -4px;
    transition: 0.15s ease-out;
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
`

const Label = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.25rem;
  margin-left: 1.9375rem;
  text-transform: uppercase;

  @media (max-width: 1199px) {
    display: none !important;
  }
`

interface ContainerProps {
  variant: Variant
}

export type Variant = 'L' | 'R' | 'SR'
