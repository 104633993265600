import styled from '@emotion/styled'
import { Button } from 'app/components/Common/Button'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { HeaderContext } from 'app/containers/Header'
import { useVocabularyData } from 'app/utils/vocabulary'
import { motion } from 'framer-motion'
import React, { memo, useContext, useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'

import { MenuItem, Props as MenuItemProps } from './MenuItem'

export interface Props {
  menuItems: MenuItemProps[]
  modalStatus?: boolean
  navigationStatus?: boolean
}

export const Navigation = memo(function Navigation({
  menuItems,
  modalStatus,
  navigationStatus,
}: Props) {
  const {
    address,
    country,
    email,
    IBEID,
    languagePrefix,
    languageCode,
    locality,
    phone,
    postalCode,
  } = useContext(HeaderContext) as any

  const [navigationLoading, setNavigationLoading] = useState(false)

  useEffect(() => {
    modalStatus
      ? setTimeout(
          () => {
            setNavigationLoading(true)
          },
          window.innerWidth > 1199 ? 2000 : 0,
        )
      : setNavigationLoading(false)
  }, [modalStatus])

  const variants = {
    visible: (i: any) => ({
      opacity: 1,
      x: 0,
      transition: {
        damping: 25,
        delay: i * 0.25,
        duration: 0.4,
        type: 'spring',
      },
    }),
    hidden: { opacity: 0, x: '-3.75rem' },
  }

  return (
    <Container row>
      {menuItems ? (
        <Nav className={navigationLoading ? 'loaded' : undefined}>
          <NavList>
            {menuItems.slice(0, menuItems.length / 2 + 1).map((item, index) => (
              <motion.li
                key={index}
                custom={index}
                animate={navigationStatus ? 'visible' : 'hidden'}
                variants={variants}
              >
                <MenuItem modalStatus={modalStatus} {...item} />
              </motion.li>
            ))}
          </NavList>
          <NavList>
            {menuItems
              .slice(menuItems.length / 2 + 1, menuItems.length)
              .map((item, index) => (
                <motion.li
                  key={index}
                  custom={index}
                  animate={navigationStatus ? 'visible' : 'hidden'}
                  variants={variants}
                >
                  <MenuItem modalStatus={modalStatus} {...item} />
                </motion.li>
              ))}
          </NavList>
        </Nav>
      ) : null}

      <Bottom
        className={modalStatus ? 'visible' : undefined}
        dial={4}
        row
        space="between"
        wrap
      >
        <Contacts dial={5} row wrap>
          {address ? (
            <Address>{`${address} ${postalCode} ${locality} - ${country}`}</Address>
          ) : null}
          <Phone
            href={`tel:${phone}`}
            onClick={() => {
              if (isMobile) {
                typeof window.gtag !== 'undefined' &&
                  window.gtag('event', 'click', {
                    event_category: 'Website',
                    event_label: 'Phone Number',
                  })

                typeof window._paq !== 'undefined' &&
                  window._paq.push([
                    'trackEvent',
                    'Website',
                    'Click',
                    'Phone Number',
                  ])
              }
            }}
          >
            {phone}
          </Phone>
          <Email
            href={`mailto:${email}`}
            onClick={() => {
              typeof window.gtag !== 'undefined' &&
                window.gtag('event', 'click', {
                  event_category: 'Website',
                  event_label: 'Email Address',
                })

              typeof window._paq !== 'undefined' &&
                window._paq.push([
                  'trackEvent',
                  'Website',
                  'Click',
                  'Email Address',
                ])
            }}
          >
            {email}
          </Email>
        </Contacts>
        {IBEID ? (
          <CTA>
            <Button
              URL={`https://hotelgiolli.simplebooking.it/ibe/hotelbooking/search?hid=${IBEID}&lang=${
                languagePrefix ? languagePrefix.toLocaleUpperCase() : 'EN'
              }`}
              label={useVocabularyData('book-now', languageCode)}
              rel="noopener"
              target="_blank"
            />
          </CTA>
        ) : null}
      </Bottom>
    </Container>
  )
})

const Container = styled(FlexBox)`
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  overflow-y: auto;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  -webkit-overflow-scrolling: auto;

  @media (max-width: 1199px) {
    flex-wrap: wrap;
    padding-top: 3.75rem;
  }
`

const Nav = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-between;
  pointer-events: none;
  position: absolute;
  top: 5.625rem;
  right: 12.639vw;
  bottom: 12.5rem;
  left: 12.639vw;
  &.loaded {
    pointer-events: initial;
    opacity: 1;
  }

  @media (max-width: 1199px) {
    width: 100%;
    flex-wrap: wrap;
    padding: 0 6.039vw;
    position: relative;
    top: 0;
    right: 0;
    bottom: auto;
    left: 0;
  }
`

const NavList = styled.ul`
  width: calc(50% - 8.681vw);

  li {
    margin-top: 0.75rem;
    &:first-of-type {
      margin-top: 0;
    }
  }

  @media (max-width: 1199px) {
    width: 100%;
    margin-top: 1.5625rem;
    li {
      margin-top: 1.5625rem;
      opacity: 1 !important;
      transform: none !important;
    }
  }
`

const Bottom = styled(FlexBox)`
  width: 100%;
  border-top: 0.5rem solid ${({ theme }) => theme.colors.variants.neutralLight4};
  opacity: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translateY(100px);
  &.visible {
    opacity: 1;
    transform: none;
    transition: 0.6s 1.4s ease-in-out;
  }

  @media (max-width: 1199px) {
    border: 0;
    flex-wrap: wrap;
    margin-top: 5.625rem;
    position: relative;
    bottom: auto;
    left: auto;
    transform: none;
    &.visible {
      transition: none;
    }
  }
`

const Contacts = styled(FlexBox)`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  line-height: 1.75rem;
  padding: 0 5.833vw;
  flex: 1;

  @media (max-width: 1199px) {
    flex: auto;
  }
`

const Address = styled.div`
  @media (max-width: 767px) {
    width: 100%;
    text-align: center;
  }
`

const Phone = styled.a`
  margin: 0 6.181vw;
  &:hover {
    text-decoration: underline;
  }

  @media (max-width: 767px) {
    margin: 0 12px;
  }
`

const Email = styled.a`
  &:hover {
    text-decoration: underline;
  }

  @media (max-width: 767px) {
    margin: 0 12px;
  }
`

const CTA = styled.div`
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
  padding: 1.375rem 5.833vw 1.875rem;

  @media (max-width: 1199px) {
    width: 100%;
    margin-top: 1.5625rem;
    padding: 0;

    a {
      width: 100%;
    }
  }
`
