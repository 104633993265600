import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { theme } from 'app/theme/index'
import { Link } from 'gatsby'
import React, { memo } from 'react'

export interface Props {
  id?: string
  title: string
  languageCode?: string
  languagePrefix?: string | null
  URL: string
}

export const MenuItem = memo(function MenuItem({ title, URL }: Props) {
  if (!title) {
    return null
  }

  const EXTERNAL_URL_REGEX = new RegExp('^(https?|ftp|www)://')
  const externalURL = URL && EXTERNAL_URL_REGEX.test(URL)

  return (
    <>
      {externalURL ? (
        <Anchor href={URL} rel="noopener" target="_blank">
          {title}
        </Anchor>
      ) : (
        <Container activeClassName="active" to={URL}>
          {title}
        </Container>
      )}
    </>
  )
})

const Style = css`
  display: flex;
  align-items: center;
  color: ${theme.colors.variants.neutralLight3};
  font-family: ${theme.fontFamily.heading};
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.25rem;
  opacity: 0.2;
  text-transform: uppercase;
  transition: 0.3s ease-in-out;
  &:hover {
    opacity: 1;
  }
  &:last-of-type {
    &:after {
      display: none;
    }
  }
  &:after {
    content: '';
    width: 2px;
    height: 16px;
    background: ${theme.colors.variants.neutralLight3};
    margin: 0 18px;
  }
`

const Anchor = styled.a`
  ${Style}
`

const Container = styled(Link)`
  ${Style}
`
